import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyAAk69eMGbgN0ajn6CExU1Mg1TrDLB39a4',
  authDomain: 'hub-system-37f3a.firebaseapp.com',
  projectId: 'hub-system-37f3a',
  storageBucket: 'hub-system-37f3a.appspot.com',
  messagingSenderId: '483344845115',
  appId: '1:483344845115:web:7d4d07cf5989c75880cf1e',
  measurementId: 'G-976P5TGG18',
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

var config1 = {
  apiKey: 'AIzaSyAAk69eMGbgN0ajn6CExU1Mg1TrDLB39a4',
  authDomain: 'hub-system-37f3a.firebaseapp.com',
  projectId: 'hub-system-37f3a',
  storageBucket: 'hub-system-37f3a.appspot.com',
  messagingSenderId: '483344845115',
  appId: '1:483344845115:web:7d4d07cf5989c75880cf1e',
  measurementId: 'G-976P5TGG18',
};

var secondaryApp = firebase.initializeApp(config1, 'Secondary');

const secauth = secondaryApp.auth();

export {
  auth,
  db,
  storage,
  secauth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
};
