import {
  GET_BRANDS,
  GET_BRANDS_ADMIN,
  GET_PLAN,
  FETCH_SUCCESS,
  GET_REGIONS,
  GET_BLOGS,
  GET_TREE_CAT,
  GET_PARTNERS,
  // GET_CONNECTIONS_LIST,
} from '../../shared/constants/ActionTypes';
import {db} from '../../@crema/services/auth/firebase/firebase';
import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// const baseurl = 'https://us-central1-sprintb2b-f68a0.cloudfunctions.net/';
const baseurl = 'https://us-central1-hub-system-37f3a.cloudfunctions.net/';

export const getbrands = (user) => {
  return (dispatch) => {
    var docRef = db
      .collection('brand_manager')
      .where('created_by', '==', user?.uid);
    // .orderBy('created_at', 'desc');
    const brandslist = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        // if (doc.data().ref_brand_code == false) {
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var data = doc.data();
        data.key = doc.id;
        brandslist.push(data);

        // doc.data() is never undefined for query doc snapshots
      });
      dispatch({type: GET_BRANDS, payload: brandslist});
    });
  };
};

export const getActiveRegions = (user) => {
  return (dispatch) => {
    var docRef = db
      .collection('brand_region')
      .where('is_active', '==', '1')
      .where('created_by', '==', user?.uid);
    const adminslist = [];
    docRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var data = doc.data();

        data.key = doc.id;

        adminslist.push(data);
      });
      dispatch({type: GET_REGIONS, payload: adminslist});
    });
  };
};
export const getregions = (user) => {
  return (dispatch) => {
    var docRef = db
      .collection('brand_region')
      // .where("is_active", "==", "1")
      .where('created_by', '==', user?.uid);
    const adminslist = [];
    docRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var data = doc.data();

        data.key = doc.id;

        adminslist.push(data);
      });
      dispatch({type: GET_REGIONS, payload: adminslist});
    });
  };
};

export function addbrand(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var metadata = {
        contentType: 'image/jpeg',
      };
      var docRef = firebase.firestore().collection('brands').doc();
      var storageRef = firebase.storage().ref();
      if (event.brand_logo) {
        var uploadTask = storageRef
          .child('/brands/' + event.brand_logo.file.name + new Date().getTime())
          .put(event.brand_logo.file, metadata);
        console.log(uploadTask);
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            reject(error);
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log('File available at', downloadURL);
              event.brand_logo = downloadURL;
              docRef.set({
                auto_id: docRef.id,
                // brand_code: "",
                brand_name: event.brand_name,
                brand_desc: event.brand_desc,
                brand_logo: event.brand_logo,
                brand_email: event.brand_email.toLowerCase(),
                brand_contactno: event.brand_contactno,
                brand_address: event.brand_address,
                is_deleted: false,
                is_active: true,
                created_at: firebase.firestore.Timestamp.fromDate(new Date()),
                updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
                created_by: firebase.auth().currentUser.uid,
                updated_by: firebase.auth().currentUser.uid,
              });
              resolve('added');
              dispatch({type: FETCH_SUCCESS});
            });
          },
        );
      } else {
        docRef.set({
          auto_id: docRef.id,
          // brand_code: "",
          brand_name: event.brand_name,
          brand_desc: event.brand_desc,
          brand_logo: event?.brand_logo ?? '',
          brand_email: event.brand_email.toLowerCase(),
          brand_contactno: event.brand_contactno,
          brand_address: event.brand_address,
          is_deleted: false,
          is_active: true,
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
        });
        resolve('added');
      }
    });
  };
}

export function createregions(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase.firestore().collection('brand_region').doc();
      docRef
        .set({
          auto_id: docRef.id,
          brand_region_code: '',
          brand_region_name: event.brand_region_name,
          brand_region_address: event.brand_region_address,
          is_active: '1',
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('Document successfully written!');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

export function updateregions(event, doc) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase.firestore().collection('brand_region').doc(doc);
      docRef
        .update({
          brand_region_name: event.brand_region_name,
          brand_region_address: event.brand_region_address,
          is_active: String(event.ref_status_id),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('Document successfully written!');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

export function addPlan(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase.firestore().collection('md_brand_plan').doc();
      docRef
        .set({
          id: docRef.id,
          auto_id: docRef.id,
          ref_plan_id: docRef.id,
          name: event.PlanName ?? '',
          tag: event.plantype ?? '',
          tagColor: '#11C15B',
          priceColor: '#0A8FDC',
          title: event.plantype ?? '',
          price: event.PlanPrice ?? 0,
          plan_last_login: '',
          max_channel_count: event.MaximumChannelcount ?? '',
          plan_subscription_expiry_date:
            event.plan_subscription_expiry_date ?? '',
          is_active: '1',
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
          pricingList: [
            {
              id: 1,
              title: 'All features from previous plan',
            },
            {
              id: 2,
              title: 'Memberships and bundles',
            },
            {
              id: 3,
              title: 'Advanced quizzes',
            },
            {
              id: 4,
              title: 'Private & hidden courses',
            },
            {
              id: 5,
              title: '2 Site admin accounts',
            },
            {
              id: 6,
              title: '5 Course admins/authors',
            },
          ],
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

export function UpdatePlan(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase
        .firestore()
        .collection('md_brand_plan')
        .doc(event.id);
      docRef
        .update({
          name: event.PlanName ?? '',
          tag: event.plantype ?? '',
          title: event.plantype ?? '',
          price: event.PlanPrice ?? 0,
          max_channel_count: event.MaximumChannelcount ?? '',
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

export const getplan = () => {
  return (dispatch) => {
    var docRef = db.collection('md_brand_plan').orderBy('created_at', 'desc');
    const adminslist = [];
    docRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        adminslist.push(data);
      });
      dispatch({type: GET_PLAN, payload: adminslist});
    });
  };
};

export function updatebrand(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var metadata = {
        contentType: 'image/jpeg',
      };
      console.log(event);
      var docRef = firebase.firestore().collection('brands').doc(event.id);
      var storageRef = firebase.storage().ref();
      if (event.isnewfile) {
        var uploadTask = storageRef
          .child('/brands/' + event.brand_logo.file.name + new Date().getTime())
          .put(event.brand_logo.file, metadata);
        // console.log(uploadTask)
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            reject(error);
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log('File available at', downloadURL);
              event.brand_logo = downloadURL;
              docRef.update({
                brand_name: event.brand_name,
                brand_desc: event.brand_desc,
                brand_logo: event.brand_logo,
                brand_email: event.brand_email.toLowerCase(),
                brand_contactno: event.brand_contactno,
                brand_address: event.brand_address,
                updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
                updated_by: firebase.auth().currentUser.uid,
              });
              resolve('updated');
              dispatch({type: FETCH_SUCCESS});
            });
          },
        );
      } else {
        docRef.update({
          brand_name: event.brand_name,
          brand_desc: event.brand_desc,
          brand_email: event.brand_email.toLowerCase(),
          brand_contactno: event.brand_contactno,
          brand_address: event.brand_address,
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        });
        resolve('updated');
        dispatch({type: FETCH_SUCCESS});
      }
    });
  };
}

export function createManager(event, user) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          ref_brand_code: user.ref_brand_code,
          ref_brand_region_code: event.ref_brand_region_code,
          brand_manager_email: event.brand_manager_email.toLowerCase(),
          brand_manager_firstname: event.brand_manager_firstname,
          brand_manager_lastname: event.brand_manager_lastname,
          brand_manager_mobilenumber: event.brand_manager_mobilenumber,
          existing_channel_partner_count: 0,
          max_channel_partner_limit_count: 0,
          ref_status_id: 1,
          ref_plan_id: '',
          subscription_expiry_date: '',
          created_by: firebase.auth().currentUser.uid,
          ref_role_id: 3,
        }),
      };
      console.log(requestOptions);
      fetch(baseurl + 'createbrandmanager', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((err) => reject(err));
    });
  };
}
export const getbrandsadmin = (user) => {
  return (dispatch) => {
    var docRef = db.collection('brand_admin').doc(user.uid);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log('Document data:', doc.data());
          dispatch({type: GET_BRANDS_ADMIN, payload: doc.data()});
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!');
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  };
};

export function updaterbrandmanager(event, doc) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase.firestore().collection('brand_manager').doc(doc);
      docRef
        .update({
          ref_brand_region_code: event.ref_brand_region_code,
          ref_status_id: event.ref_status_id,
          brand_manager_firstname: event.brand_manager_firstname,
          brand_manager_lastname: event.brand_manager_lastname,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('Document successfully written!');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

// export function createPartner(event) {
//   return () => {
//     console.log(event);
//     return new Promise((resolve, reject) => {
//       const requestOptions = {
//         method: 'POST',
//         headers: {'Content-Type': 'application/json'},
//         body: JSON.stringify({
//           channel_partner_email: event.channel_partner_email,
//           channel_partner_firstname: event.channel_partner_firstname,
//           channel_partner_lastname: event.channel_partner_lastname,
//           channel_partner_mobilenumber: event.channel_partner_mobilenumber,
//           created_by: firebase.auth().currentUser.uid,
//         }),
//       };
//       fetch(baseurl + 'createchannelpartner', requestOptions)
//         .then((response) => response.json())
//         .then((data) => resolve(data))
//         .catch((err) => reject(err));
//     });
//   };
// }
// export function onUpdatePartner(event) {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       var docRef = firebase
//         .firestore()
//         .collection('channel_partner_basic_info')
//         .doc(event.id);
//       docRef
//         .update({
//           channel_partner_code: '',
//           channel_partner_email: event.channel_partner_email ?? '',
//           channel_partner_firstname: event.channel_partner_firstname ?? '',
//           channel_partner_lastname: event.channel_partner_lastname ?? '',
//           channel_partner_mobilenumber:
//             event.channel_partner_mobilenumber ?? '',
//           channel_partner_last_login: '',
//           user_device_token: '',
//           user_device_type: '',
//           is_active: '1',
//           updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
//           updated_by: firebase.auth().currentUser.uid,
//         })
//         .then(() => {
//           console.log('Document successfully written!');
//           resolve('added');
//           dispatch({type: FETCH_SUCCESS});
//         })
//         .catch((error) => {
//           console.error('Error writing document: ', error);
//           reject(error);
//         });
//     });
//   };
// }
// export function onDeletePartner(event) {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       console.log(event);

//       var docRef = firebase
//         .firestore()
//         .collection('channel_partner_basic_info')
//         .doc(event);
//       // Set the "capital" field of the city 'DC'
//       docRef
//         .update({
//           is_active: false,
//         })
//         .then(() => {
//           console.log('Document successfully updated!');
//           resolve('Document successfully updated!');
//           dispatch({type: FETCH_SUCCESS});
//         })
//         .catch((error) => {
//           console.error('Error updating document: ', error);
//           reject(error);
//         });
//     });
//   };
// }

export const fetchBlogs = (user) => {
  return (dispatch) => {
    // let user = JSON.parse(localStorage.getItem('authUser'));
    var docRef = db
      .collection('blogs')
      // .where('ref_manager_id', '==', user?.uid)
      .where('created_by', '==', user?.uid ?? '');
    const bloglist = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        // if (doc.data().ref_brand_code == false) {
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var data = doc.data();
        data.auto_id = doc.id;
        // data.auth_id = doc.id;
        bloglist.push(data);

        // doc.data() is never undefined for query doc snapshots
      });
      dispatch({type: GET_BLOGS, payload: bloglist});
    });
  };
};
export const GetBlog = (user) => {
  return (dispatch) => {
    var docRef = db
      .collection('blogs')
      .where('ref_manager_id', '==', user?.uid);
    // .where('created_by', '==', user?.uid);
    const bloglist = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        // if (doc.data().ref_brand_code == false) {
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var data = doc.data();
        data.auto_id = doc.id;
        // data.auth_id = doc.id;
        bloglist.push(data);

        // doc.data() is never undefined for query doc snapshots
      });
      dispatch({type: GET_BLOGS, payload: bloglist});
    });
  };
};
export function AddBlog(editorState, event, fileList) {
  console.log('video', event);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // var metadata = {
      //   contentType: 'video/mp4',
      // };
      var docRef = firebase.firestore().collection('blogs').doc();
      var storageRef = firebase.storage().ref();
      console.log(fileList);
      if (event.blog_file) {
        fileList.forEach((f) => {
          console.log(f);
          console.log('video process', storageRef);
          var uploadTask = storageRef.child('/blogs/' + f.name).put(f);
          console.log(uploadTask);
          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              reject(error);
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break;
                case 'storage/canceled':
                  // User canceled the upload
                  break;

                // ...

                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log('File available at', downloadURL);
                docRef.set({
                  auto_id: docRef.id,
                  ref_manager_id: event.ref_manager_id,
                  blog_title: event.blog_title,
                  blog: editorState,
                  blog_file: downloadURL,
                  is_read: false,
                  is_notify: false,
                  is_active: '1',
                  created_at: firebase.firestore.Timestamp.fromDate(new Date()),
                  updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
                  created_by: firebase.auth().currentUser.uid,
                  updated_by: firebase.auth().currentUser.uid,
                });
                resolve('added');
                // }
              });
            },
          );
        });
      } else {
        console.log('module', event);
        let post = {
          auto_id: docRef.id,
          ref_manager_id: event.ref_manager_id,
          blog_title: event.blog_title,
          blog: editorState,
          is_read: true,
          // blog_file: downloadURL ?? "",
          is_active: '1',
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
        };
        docRef.set(post);
        resolve('added');
        dispatch({type: FETCH_SUCCESS});
      }
    });
  };
}

export function updateblog(event, fileList) {
  console.log(event);
  console.log(fileList);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase.firestore().collection('blogs').doc(event.id);
      console.log(fileList.length);
      var storageRef = firebase.storage().ref();
      if (fileList.length === 0) {
        docRef
          .update({
            blog_title: event.blog_title,
            blog: event.blog,
            is_active: event.is_active,
            updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
            updated_by: firebase.auth().currentUser.uid,
          })
          .then(() => {
            console.log('Document successfully updated!');
            resolve('updated');
            dispatch({type: FETCH_SUCCESS});
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        if (event.blog_file) {
          fileList.forEach((f) => {
            var uploadTask = storageRef.child('/blogs/' + f.name).put(f);
            // console.log(uploadTask)
            uploadTask.on(
              firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    // console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    // console.log('Upload is running');
                    break;
                }
              },
              (error) => {
                reject(error);
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;
                  case 'storage/canceled':
                    // User canceled the upload
                    break;

                  // ...

                  case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                }
              },
              () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  console.log('File available at', downloadURL);
                  docRef.update({
                    blog_file: downloadURL,
                  });
                  resolve('updated');
                  dispatch({type: FETCH_SUCCESS});
                });
              },
            );
          });
        }
      }
    });
  };
}
// export function updateblog(event) {
//   console.log(event)
//   console.log(event.id)
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       var docRef = firebase
//         .firestore()
//         .collection('blogs')
//         .doc(event.id);
//       docRef
//         .update({
//           blog_title: event.blog_title,
//           blog: event.blog,
//           is_active: event.is_active,
//           // is_active: String(event.is_active) === 'true' ? true : false,
//           updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
//           updated_by: firebase.auth().currentUser.uid
//         })
//         .then(() => {
//           console.log('Document successfully written!');
//           resolve('added');
//           dispatch({type: FETCH_SUCCESS});
//         })
//         .catch((error) => {
//           console.error('Error writing document: ', error);
//           reject(error);
//         });
//     });
//   };
// }
export function onDeleteBlog(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(event);

      var docRef = firebase.firestore().collection('blogs').doc(event);
      docRef
        .update({
          is_active: '0',
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully updated!');
          resolve('Document successfully updated!');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          reject(error);
        });
    });
  };
}
// export function deleteblog(id, value) {
//   console.log(id, value)
//   return new Promise((resolve, reject) => {
//       console.log(id)
//       firebase.firestore().collection("blogs").doc(id).update({
//           is_active: value,
//       }).then(() => {
//           resolve('Deleted')
//       }).catch(err => {
//           reject(err)
//       })
//   })
// }
// export const GetTreeCategories = (user) => {
//   return (dispatch) => {
//     const bloglist = [];
//     var subcatlist = [];
//     var subcount = 0;
//     var docRef = db
//       .collection('product_categories')
//       .where('created_by', '==', user?.uid);

//     docRef.get().then((querySnapshot) => {
//       // console.log(querySnapshot.size);
//       querySnapshot.forEach((cat) => {
//         // if (doc.data().ref_brand_code == false) {
//         //console.log(cat.id, ' => ', cat.data());
//         db.collection('product_subcategories')
//           .where('ref_category_id', '==', cat.id)
//           .get()
//           .then((querySubcat) => {
//             querySubcat.forEach((doc) => {
//               subcount++;
//               //console.log(subcount);
//               // doc.data() is never undefined for query doc snapshots

//               var subcat_obj = {
//                 title: doc.data().subcategory_name,
//                 key: doc.id,
//                 is_cat: false,
//               };
//               subcatlist.push(subcat_obj);

//               //console.log(querySubcat.size, subcount);
//               //console.log(subcatlist);
//               if (querySubcat.size === subcount) {
//                 var obj_arr = {
//                   title: cat.data().category_name,
//                   key: cat.id,
//                   children: subcatlist,
//                   is_cat: true,
//                 };
//                 subcount = 0;
//                 subcatlist = [];
//                 bloglist.push(obj_arr);
//                 // console.log(bloglist);
//                 if (querySnapshot.size === bloglist.length) {
//                   dispatch({type: GET_TREE_CAT, payload: bloglist});
//                 }
//               }
//             });
//           })
//           .catch((error) => {
//             console.log('Error getting documents: ', error);
//           });

//         // doc.data() is never undefined for query doc snapshots
//       });
//     });
//   };
// };

// export const GetTreeCategories = () => {
//   return (dispatch) => {
//     const bloglist = [];
//     var subcatlist = [];
//     var subcount = 0;
//     var docRef = db
//       .collection('product_categories')
//       // .where('created_by', '==', user?.uid);

//     docRef.get().then((querySnapshot) => {
//       console.log(querySnapshot.size);

//       if (querySnapshot.empty) {
//         dispatch({type: GET_TREE_CAT, payload: bloglist});
//       } else {
//       querySnapshot.forEach((cat) => {
//         // if (doc.data().ref_brand_code == false) {
//         //console.log(cat.id, ' => ', cat.data());
//         db.collection('product_subcategories')
//           .where('ref_category_id', '==', cat.id)
//           .get()
//           .then((querySubcat) => {
//             console.log(querySubcat.size)
//             querySubcat.forEach((doc) => {

//               subcount++;
//               console.log(subcount);
//               // doc.data() is never undefined for query doc snapshots

//               var subcat_obj = {
//                 title: doc.data().subcategory_name,
//                 key: doc.id,
//                 is_cat: false,
//               };
//               subcatlist.push(subcat_obj);
//               console.log(subcatlist);

//               if (querySubcat.size === subcount) {
//                 //  const arr_img = [];
//                 var obj_arr = {
//                   title: cat.data().category_name,
//                   key: cat.id,
//                   children: subcatlist,
//                   is_cat: true,
//                 };
//                 subcount = 0;
//                 subcatlist = [];
//                 console.log(obj_arr)
//                 bloglist.push(obj_arr);
//                 console.log(bloglist)
//                 // console.log(bloglist.length)
//                 // console.log(querySnapshot.size)
//                 // console.log(querySnapshot.size === bloglist.length)
//                 // if (querySnapshot.size === bloglist.length) {
//                   dispatch({type: GET_TREE_CAT, payload: bloglist});
//                 // }

//               }
//             });

//           })
//           .catch((error) => {
//             console.log('Error getting documents: ', error);
//           });
//       })};
//     });

//   };
// };
export const GetTreeCategories = (user) => {
  console.log(user);
  return (dispatch) => {
    // let user = JSON.parse(localStorage.getItem('authUser'));
    var docRef = db.collection('product_categories');
    // .where('created_by', '==', user?.uid)
    const categories = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        // if (doc.data().ref_brand_code == false) {
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var data = doc.data();
        // data.auto_id = doc.id;
        // data.auth_id = doc.id;
        data.key = doc.id;
        data.title = doc.data().category_name;
        categories.push(data);
        console.log(categories);
        // doc.data() is never undefined for query doc snapshots
      });
      dispatch({type: GET_TREE_CAT, payload: categories});
    });
  };
};

export const fetchPartners = (user, {is_all = true}) => {
  return (dispatch) => {
    var docRef;
    if (is_all) {
      docRef = db
        .collection('channel_partner_basic_info')
        // .where('created_by.created_by', '==', [user?.uid]);
        .where('created_by', 'array-contains-any', [
          {
            manager_id: user?.uid ?? '',
            is_active: true,
          },
          {manager_id: user?.uid ?? '', is_active: false},
        ]);
    } else {
      docRef = db
        .collection('channel_partner_basic_info')
        // .where('created_by.created_by', '==', [user?.uid]);
        .where('created_by', 'array-contains', {
          manager_id: user?.uid ?? '',
          is_active: true,
        });
    }
    // const q = query(citiesRef, where("regions", "array-contains", "west_coast"));
    console.log(docRef);
    const partners = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot.size);
      partners.no_of_partner = querySnapshot.size;
      querySnapshot.forEach((doc) => {
        // if (doc.data().ref_brand_code == false) {
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var data = doc.data();
        data.auto_id = doc.id;
        data.key = doc.id;
        var index = data?.created_by?.findIndex(
          (created) => created.manager_id === user?.uid,
        );
        if (index >= 0) {
          data.is_active = data?.created_by[index].is_active;
        }
        partners.push(data);
        console.log(partners);
        // doc.data() is never undefined for query doc snapshots
      });
      dispatch({type: GET_PARTNERS, payload: partners});
    });
  };
};
// export const getConnectionList = (user) => {
//   return (dispatch) => {
//     var docRef = db
//       .collection('channel_partner_basic_info')
//       .where('created_by', 'array-contains-any', [user?.uid]);
//     // .orderBy('created_at', 'desc');
//     const partners = [];
//     docRef.get().then((querySnapshot) => {
//       console.log(querySnapshot.size);
//       partners.no_of_partner = querySnapshot.size;
//       querySnapshot.forEach((doc) => {
//         // if (doc.data().ref_brand_code == false) {
//         console.log(doc.id, ' => ', doc.data(), querySnapshot);
//         var data = doc.data();
//         data.auto_id = doc.id;
//         data.key = doc.id;

//         partners.push(data);
//         console.log(partners)
//         // doc.data() is never undefined for query doc snapshots
//       });
//       dispatch({type: GET_CONNECTIONS_LIST, payload: partners});
//     });
//   };
// };
