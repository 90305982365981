import {GET_PARTNERS, FETCH_SUCCESS} from '../../shared/constants/ActionTypes';
import {db} from '../../@crema/services/auth/firebase/firebase';
import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import {message} from 'antd';

// const baseurl = 'https://us-central1-sprintb2b-f68a0.cloudfunctions.net/';
const baseurl = 'https://us-central1-hub-system-37f3a.cloudfunctions.net/';

export const GetPartners = (user) => {
  return (dispatch) => {
    var docRef = db
      .collection('channel_partner_basic_info')
      .where('created_by', 'array-contains-any', [user?.uid]);
    const partners = [];
    docRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        data.auto_id = doc.id;
        data.key = doc.id;
        partners.push(data);
      });
      dispatch({type: GET_PARTNERS, payload: partners});
    });
  };
};

export const GetPartners1 = (user) => {
  return new Promise((resolve) => {
    var docRef = db
      .collection('channel_partner_basic_info')
      .where('created_by', 'array-contains-any', [
        {
          manager_id: user?.uid,
          is_active: true,
        },
        {manager_id: user?.uid, is_active: false},
      ]);
    const partners = [];
    docRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        data.auto_id = doc.id;
        data.key = doc.id;
        data.username =
          doc.data().channel_partner_firstname +
          ' ' +
          doc.data().channel_partner_lastname;
        // data.projectID = '4515e510-8f91-4113-a322-9446b251f3e6';
        partners.push(data);
        resolve(partners);
      });
      resolve(partners);
    });
  });
};
// export const GetChatPartners = () => {
//   // return (dispatch) => {
//   var myHeaders = new Headers();
//   myHeaders.append('PRIVATE-KEY', '440978ec-e659-4731-a3ba-e345609413fd');

//   var requestOptions = {
//     method: 'GET',
//     headers: myHeaders,
//     redirect: 'follow',
//   };

//   fetch('https://api.chatengine.io/users/', requestOptions)
//     .then((response) => response.text())
//     .then((result) => {
//       console.log(result);
//     })
//     .catch((error) => console.log('error', error));
//   // };
// };

// export function onAddNewPartner(event) {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       var docRef = firebase
//         .firestore()
//         .collection('channel_partner_basic_info')
//         .doc();
//       docRef
//         .set({
//           auto_id: docRef.id,
//           channel_partner_code: '',
//           channel_partner_email: event.channel_partner_email,
//           channel_partner_firstname: event.channel_partner_firstname,
//           channel_partner_lastname: event.channel_partner_lastname,
//           channel_partner_mobilenumber: event.channel_partner_mobilenumber,
//           channel_partner_last_login: '',
//           user_device_token: '',
//           user_device_type: '',
//           is_active: '1',
//           created_at: firebase.firestore.Timestamp.fromDate(new Date()),
//           updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
//           created_by: [firebase.auth().currentUser.uid],
//           updated_by: firebase.auth().currentUser.uid,
//         })
//         .then(() => {
//           console.log('Document successfully written!');
//           resolve('added');
//           dispatch({type: FETCH_SUCCESS});
//         })
//         .catch((error) => {
//           console.error('Error writing document: ', error);
//           reject(error);
//         });
//     });
//   };
// }

export function createPartner(event) {
  return () => {
    console.log(event);

    return new Promise((resolve, reject) => {
      const chatbaseUrl = 'https://api.chatengine.io';

      var raw = JSON.stringify({
        username:
          event.channel_partner_firstname +
          ' ' +
          event.channel_partner_lastname,
        first_name: event.channel_partner_firstname,
        last_name: event.channel_partner_lastname,
        secret: event.channel_partner_email.toLowerCase(),
        created: firebase.auth().currentUser.uid,
      });
      console.log(raw);
      var requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'PRIVATE-KEY': 'c957a3f9-87c1-4075-a6f1-6a0ca2ec5d93',
        },
        body: raw,
        redirect: 'follow',
      };
      console.log(requestOptions);
      fetch(chatbaseUrl + '/users/', requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log(result, typeof result);
          var contact = JSON.parse(result);
          console.log(contact);
          var chat_api_id = contact.id;
          var username = contact.username;
          console.log(username);
          if (contact.message === String('This username is taken.')) {
            message.error('This username is already taken in ChatEngine');
          } else {
            const requestOptions = {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({
                channel_partner_email:
                  event.channel_partner_email.toLowerCase(),
                channel_partner_firstname: event.channel_partner_firstname,
                channel_partner_lastname: event.channel_partner_lastname,
                channel_partner_mobilenumber:
                  event.channel_partner_mobilenumber,
                // created_by: partData,
                is_manager_active: true,
                created_by: firebase.auth().currentUser.uid,
                is_active: '1',
                chat_api_id: chat_api_id,
                username: username,
              }),
            };
            console.log(requestOptions);
            fetch(baseurl + 'createchannelpartner', requestOptions)
              .then((response) => response.json())
              .then((data) => resolve(data))
              .catch((err) => reject(err));
          }
        })
        .catch((error) => console.log('error', error));
    });
  };
}

export function onUpdatePartner(event, id) {
  console.log(event, id);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase
        .firestore()
        .collection('channel_partner_basic_info')
        .doc(event.id);
      firebase
        .firestore()
        .collection('channel_partner_basic_info')
        .doc(event.id)
        .get()
        .then(async function (doc) {
          if (doc.size === 0) resolve(null);
          else {
            var createdby = doc.data().created_by;
            if (
              createdby === undefined ||
              createdby === null ||
              createdby.length === 0
            ) {
              resolve(null);
            } else {
              var index = createdby?.findIndex(
                (created) => created.manager_id === id,
              );
              if (index >= 0) {
                createdby[index].is_active =
                  String(event.is_active) === 'true' ? true : false;
              }
            }
            docRef
              .update({
                channel_partner_email:
                  event.channel_partner_email.toLowerCase(),
                channel_partner_firstname:
                  event.channel_partner_firstname ?? '',
                channel_partner_lastname: event.channel_partner_lastname ?? '',
                channel_partner_mobilenumber:
                  event.channel_partner_mobilenumber ?? '',
                // is_active: event.is_active ?? '',
                created_by: createdby,
                updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
              })
              .then(() => {
                console.log('Document successfully written!');
                resolve('added');
                dispatch({type: FETCH_SUCCESS});
              })
              .catch((error) => {
                console.error('Error writing document: ', error);
                reject(error);
              });
          }
        });
    });
  };
}

export function onDeletePartner(event, id) {
  console.log(event, id);
  return (dispatch) => {
    return new Promise((resolve) => {
      firebase
        .firestore()
        .collection('channel_partner_basic_info')
        .doc(event)
        .get()
        .then(async function (doc) {
          if (doc.size === 0) resolve(null);
          else {
            var createdby = doc.data().created_by;
            var prdId = doc.data().ref_product_id;
            console.log(createdby);
            if (
              createdby === undefined ||
              createdby === null ||
              createdby.length === 0
            ) {
              resolve(null);
            } else {
              var index = createdby?.findIndex(
                (created) => created.manager_id === id,
              );
              if (index >= 0) {
                createdby[index].is_active = false;
                firebase
                  .firestore()
                  .collection('products')
                  .where('created_by', '==', id)
                  .get()
                  .then((docs) => {
                    console.log('documents', docs);
                    if (docs.size === 0) {
                      resolve(
                        updatepartner({
                          event: event,
                          createdby: createdby,
                          prdId: prdId,
                        }),
                      );
                    }
                    var productIds = [];
                    docs.forEach((document) => {
                      productIds.push(document.data().auto_id);
                    });
                    // docs.data().map((prod) => {
                    //   productIds.push(prod.auto_id);
                    // });
                    console.log('productIds', productIds);
                    if (
                      prdId !== undefined &&
                      prdId !== null &&
                      prdId.length > 0 &&
                      productIds !== null &&
                      productIds.length > 0
                    ) {
                      prdId = prdId.filter(function (i) {
                        return this.indexOf(i) < 0;
                      }, productIds);
                      console.log(prdId);
                    }
                    resolve(
                      updatepartner({
                        event: event,
                        createdby: createdby,
                        prdId: prdId,
                      }),
                    );
                  })
                  .catch((err) => {
                    console.error('Error updating document: ', err);
                    resolve(
                      updatepartner({
                        event: event,
                        createdby: createdby,
                        prdId: prdId,
                      }),
                    );
                  });
              } else {
                console.log('Document successfully updated!');
                resolve('Document successfully updated!');
                dispatch({type: FETCH_SUCCESS});
              }
            }
          }
        });
      // var createdby = docRef.get().docs[0].data().created_by;
    });
  };
}
export function updatepartner({event, createdby, prdId}) {
  console.log('partner-id---', event);
  return new Promise((resolve, reject) => {
    var docRef = firebase
      .firestore()
      .collection('channel_partner_basic_info')
      .doc(event);
    console.log(docRef);
    docRef
      .update({
        created_by: createdby,
        ref_product_id: prdId,
        updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then(async () => {
        await firebase
          .firestore()
          .collection('meetings')
          .where('partner_id', '==', event)
          .get()
          .then((docs) => {
            docs.forEach(async (document) => {
              await firebase
                .firestore()
                .collection('meetings')
                .doc(document.id)
                .update({is_active: false});
            });
          })
          .catch((errr) => {
            console.error(errr);
          });
        console.log('Document successfully updated!');
        resolve('Document successfully updated!');
      })
      .catch((error) => {
        console.error('Error updating document: ', error);
        reject(error);
      });
  });
}
