import {authRole} from '../../../shared/constants/AppEnums';

export const getUserFromAuth0 = (user) => {
  console.log(user);
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      brandcode: user.brandcode,
      role: authRole.user,
    };
  return user;
};

export const getUserFromFirebase = (user) => {
  console.log(user);
  if (localStorage.getItem('role') === 'manager') {
    if (user)
      return {
        id: 1,
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        brandcode: localStorage.getItem('brand_logo_url'),
        photoURL: user.photoURL ? user.photoURL : user.photoURL,
        role: authRole.user,
      };
    return user;
  } else {
    if (user)
      return {
        id: 1,
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        brandcode: localStorage.getItem('brand_logo_url'),
        photoURL: user.photoURL ? user.photoURL : user.photoURL,
        role: authRole.admin,
      };
    return user;
  }
};
export const getUserFromAWS = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : 'Crema User',
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.user,
    };
  return user;
};

export const getUserFromJwtAuth = (user) => {
  console.log(user);
  if (user)
    return {
      id: 1,
      uid: user._id,
      displayName: user.name,
      email: user.email,
      photoURL: user.avatar,
      brandcode: user.brandcode,
      role: authRole.user,
    };
  return user;
};
